import React, { useEffect, useState, useContext, useRef } from 'react';
import { css } from '@emotion/react';
import _debounce from 'lodash/debounce';

// hooks

// components
import { BaseSideDrawer } from '@zola/zola-ui/src/components/SideDrawer';
import { CloseIcon } from '@zola/zola-ui/src/components/SvgIcons/Close';

// context
import {
  useWebsiteThemeContext,
  WebsiteThemeContextProvider,
  WebsiteThemeContextStateType,
} from '~/components/publicWebsiteV2/context';
import { DeviceContext } from '~/contexts/DeviceContext';

// utils
import { MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';
import { mapModeThemeToInputField } from '~/components/publicWebsiteV2/util/genericFormElements.styles';
import { isDesktop } from '@zola-helpers/client/dist/es/util/responsive';
import { useFlyoutPanelContext } from '~/components/publicWebsiteV2/contexts/flyoutPanelContext';
import { mapFontValuesToComponentFactory } from '~/components/publicWebsiteV2/util/mappers/mapFontValuesToComponent';
import { mapButtonStylesToComponent } from '~/components/publicWebsiteV2/util/mappers/mapButtonStyles';
import {
  DEFAULT_HEADER_FONT_VALUES,
  DEFAULT_BODY_FONT_VALUES,
  DEFAULT_ACCENT_COLOR,
  DEFAULT_BACKGROUND_COLOR,
} from '~/components/publicWebsiteV2/constants';
import { mapLinkStylesToComponent } from '../../util/mappers/mapLinkStyles';
import type { FullComponentFontView } from '../../util/mappers/types';

// styles
import { FlyoutBody, FlyoutHeader } from './FlyoutPanel.styles';

const FlyoutPanel = (): JSX.Element => {
  // const { onDisableLock, onEnableLock } = useScrollLock();
  const { device } = useContext(DeviceContext);
  const drawerRef = useRef(null);

  const [isDesktopSize, setIsDesktopSize] = useState(device?.isDesktop());

  const { state } = useWebsiteThemeContext();
  const { wedding } = state;
  const {
    state: { isOpen, content, onClose },
    actions: { hideFlyoutPanel },
  } = useFlyoutPanelContext();

  // Inject font family into defaults
  const globalHeaderFontFamily =
    wedding?.public_theme_v2?.theme_group_font_families?.HEADER?.font_family;
  const globalBodyFontFamily =
    wedding?.public_theme_v2?.theme_group_font_families?.BODY?.font_family;

  const defaultHeaderFontValues: FullComponentFontView = {
    ...DEFAULT_HEADER_FONT_VALUES,
    ...globalHeaderFontFamily,
    fontFamily: globalHeaderFontFamily?.name || DEFAULT_HEADER_FONT_VALUES.fontFamily,
  };
  const defaultBodyFontValues: FullComponentFontView = {
    ...DEFAULT_BODY_FONT_VALUES,
    ...globalBodyFontFamily,
    fontFamily: globalBodyFontFamily?.name || DEFAULT_BODY_FONT_VALUES.fontFamily,
  };
  const styleGlobalBodyFont = mapFontValuesToComponentFactory({
    fallback: defaultBodyFontValues,
    mediaQuery: MEDIA_QUERY,
  });

  const DefaultButton = mapButtonStylesToComponent(
    globalBodyFontFamily?.name || DEFAULT_BODY_FONT_VALUES.fontFamily,
    DEFAULT_ACCENT_COLOR
  );

  const standardizedComponentLibrary: WebsiteThemeContextStateType['components'] = {
    globalHeaderFontValues: defaultHeaderFontValues,
    globalBodyFontValues: defaultBodyFontValues,
    styleGlobalBodyFont,
    globalAccentColor: DEFAULT_ACCENT_COLOR,
    globalInputFieldStyles: mapModeThemeToInputField(false, defaultBodyFontValues.color),
    backgroundColors: {
      nav: DEFAULT_BACKGROUND_COLOR,
      homepageContent: DEFAULT_BACKGROUND_COLOR,
      content: DEFAULT_BACKGROUND_COLOR,
      global: DEFAULT_BACKGROUND_COLOR,
    },
    ThemedButton: DefaultButton,
    CmsEntityBodyLink: mapLinkStylesToComponent(defaultBodyFontValues, DEFAULT_BACKGROUND_COLOR),
    cmsEntityComponentHeaderFontValues: defaultHeaderFontValues,
    styleCmsEntityHeaderFont: mapFontValuesToComponentFactory({
      fallback: defaultHeaderFontValues,
      responsiveFontSize: true,
      mediaQuery: MEDIA_QUERY,
    }),
    cmsEntityComponentBodyFontValues: defaultBodyFontValues,
    styleCmsEntityBodyFont: styleGlobalBodyFont,
    cmsEntityComponentBodyFont2Values: defaultBodyFontValues,
    styleCmsEntityBodyFont2: styleGlobalBodyFont,
  };

  const newInitialState = {
    ...state,
    components: standardizedComponentLibrary,
  };

  useEffect(() => {
    setIsDesktopSize(isDesktop());
    const handleResize = _debounce(() => {
      setIsDesktopSize(isDesktop());
    }, 1000);
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      // Need to trigger the resize event to get the correct window.innerWidth value on load
      window.dispatchEvent(new Event('resize'));
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    hideFlyoutPanel();
  };

  return (
    <WebsiteThemeContextProvider initialState={newInitialState}>
      <div style={{ zIndex: 2000 }}>
        <BaseSideDrawer
          width={isDesktopSize ? '80%' : '100%'}
          displayDrawer={isOpen}
          onClose={handleClose}
          bodyRef={drawerRef}
        >
          {{
            header: () => (
              <FlyoutHeader className="IcEnableContainerAndCursor" data-testid="FlyoutHeader">
                <CloseIcon
                  onClick={handleClose}
                  height={20}
                  width={20}
                  css={css`
                    cursor: pointer;
                  `}
                  data-testid="FlyoutPanel__close"
                />
              </FlyoutHeader>
            ),
            content: (
              <FlyoutBody className="IcDisableChildrenOfContainer" data-testid="FlyoutPanel">
                {content}
              </FlyoutBody>
            ),
          }}
        </BaseSideDrawer>
      </div>
    </WebsiteThemeContextProvider>
  );
};

export default FlyoutPanel;
